import BottomButton from "@/components/BottomButton";
import carInsuranceInfectious from "@/components/documents/carInsuranceInfectious";
import carInsuranceCritically from "@/components/documents/carInsuranceCritically";
import carInsuranceDisclaimer from "@/components/documents/carInsuranceDisclaimer";
import carInsuranceNotice from "@/components/documents/carInsuranceNotice";
import carInsuranceDisclosure from "@/components/documents/carInsuranceDisclosure";
import carInsurancePolicy from "@/components/documents/carInsurancePolicy";
import carInsuranceDisease from "@/components/documents/carInsuranceDisease";

import { registerComponent } from "@/utils";
import { Tab, Tabs } from "vant";

export const components = registerComponent({
  Tab,
  Tabs,
  BottomButton,
  carInsuranceInfectious,
  carInsuranceCritically,
  carInsuranceDisclaimer,
  carInsuranceNotice,
  carInsuranceDisclosure,
  carInsurancePolicy,
  carInsuranceDisease
});

export default { components };
