<template>
  <div>
    <van-tabs swipeable>
      <van-tab
        title="平安个人出行不便损失补偿保险条款"
      >
        <carInsurance-infectious></carInsurance-infectious>
      </van-tab>
      <van-tab title="平安驾驶人员意外伤害保险条款">
        <carInsurance-critically></carInsurance-critically>
      </van-tab>
      <van-tab title="平安法定传染病身故保险条款">
        <carInsurance-disease></carInsurance-disease>
      </van-tab>
    </van-tabs>
    <bottom-button></bottom-button>
  </div>
</template>
<script>
import { Vue, Component } from "vue-property-decorator";
import { components } from "./options";

@Component({ components })
class Disclaimer extends Vue {
   static name = "Disclaimer";
}
export default Disclaimer;
</script>
<style lang="scss" src="../index.scss" scoped></style>
